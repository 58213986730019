html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #000000;
  cursor: none;
}

canvas {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

#preloader {
  color: #fff;
  font: 20px monospace;
  left: 50vw;
  position: absolute;
  top: 30vh;
  transform: translate(-50%, -50%);
  width: 200px;
  z-index: 1;
}

#preloader > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
